<template>
	<div class="commonHeight">
		<div class="flex header">
			<h2>客户数据分析</h2>
		</div>
		<el-form ref="form" class="searchCriteria" label-suffix=":" :model="searchForm" label-width="100px">
			<div class="flex mb-1">
				<el-form-item label="停车场">
					<el-select v-model="searchForm.parkId" placeholder="请选择停车场">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.parkingName" :key="ind" v-for="(item,ind) in parkingList"
											 :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户类型">
					<el-select v-model="searchForm.memberTypeId" placeholder="请选择用户类型">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.name" :key="ind" v-for="(item,ind) in memberTypeList"
											 :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="录入人">
					<el-select v-model="searchForm.handler" placeholder="请选择录入人">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.dataHandler" :key="ind" v-for="(item,ind) in handlerList"
											 :value="item.dataHandler"></el-option>
					</el-select>
				</el-form-item>
			</div>
      <div class="flex mb-1">
        <el-form-item label="车牌号码">
          <el-input v-model="searchForm.carNo" placeholder="请输入车牌号码"></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="searchForm.companyName" placeholder="请输入公司名称"></el-input>
        </el-form-item>
      </div>
			<div class="flex">
				<el-form-item label="统计时间段">
					<el-date-picker
							v-model="searchForm.entryTime"
							type="date"
							placeholder="开始时间">
					</el-date-picker>
					--
					<el-date-picker
							v-model="searchForm.outTime"
							type="date"
							placeholder="结束时间">
					</el-date-picker>
				</el-form-item>
				<div class="averageWid btnGroup">
					<el-button @click="getDurationList">查询</el-button>
					<el-button @click="reloadSearch">重置</el-button>
				</div>
			</div>
		</el-form>
		<div class="analysis">
			<div class="echarts">
				<h3 class="tit">停车时长(h)+进场频次(次)</h3>
				<histogram v-if="parking.xValue.length>0" id="parking" type="bar" :data="parking" ref="parkingChart"></histogram>
				<!--				<div style="width: 100%; height:350px" id="parking" ref="echarts"></div>-->
			</div>
			<div class="echarts">
				<h3 class="tit">损失金额(单位：元)</h3>
				<histogram v-if="amount.xValue.length>0" id="amount" type="bar" :data="amount" ref="amount"></histogram>
			</div>
			<div class="echarts">
				<h3 class="tit">其他损失金额(单位：元)</h3>
				<histogram v-if="otherLoss.xValue.length>0" id="otherLoss" type="bar" :data="otherLoss" ref="otherLoss"></histogram>
			</div>
		</div>
		<div class="rightCount">
			<div class="count ">
				<h3 class="tit">日平均值统计</h3>
				<div class="number">
					<p class="parkTime">{{ average.parkTimeHour }}h{{average.parkTimeMin}}min</p>
					<span>平均停车时长</span>
					<p class="inPark">{{ average.inPark }}</p>
					<span>平均进场次数</span>
					<p class="outPark">{{ average.outPark }}</p>
					<span>平均出场次数</span>
					<p class="parkTime">{{ average.incomeTotal }}</p>
					<span>平均收入金额(元)</span>
					<p class="loss">{{ average.loss }}</p>
					<span>平均亏损金额(元)</span>
				</div>
			</div>
			<div class="count ">
				<h3 class="tit">数值统计</h3>
				<div class="number">
					<p class="parkTime">{{ count.parkTimeHour }}h{{count.parkTimeMin}}min</p>
					<span>停车时长</span>
					<p class="inPark">{{ count.inPark }}</p>
					<span>进场次数</span>
					<p class="outPark">{{ count.outPark }}</p>
					<span>出场次数</span>
					<p class="parkTime">{{ count.incomeTotal }}</p>
					<span>收入金额(元)</span>
					<p class="loss">{{ count.loss }}</p>
					<span>总损失金额(元)</span>
					<p class="lossCoupon">{{ count.lossCoupon }}</p>
					<span>优惠券亏损金额(元)</span>
					<div v-if="searchForm.memberTypeId==''">
						<p class="govermentLoss">{{ count.lossGoverment }}</p>
						<span>vip客户亏损金额(元)</span>
						<p class="coopLoss">{{ count.lossCooperation }}</p>
						<span>合作伙伴损金额(元)</span>
						<p class="employLoss">{{ count.lossEmploy }}</p>
						<span>内部人员亏损金额(元)</span>
					</div>
				</div>
			</div>
		</div>
		<div style="clear:both;"></div>
	</div>
</template>

<script>
import { getCarParkInfoList,getDurationAndTotalLoss } from "@/api/api";
import moment from "moment";

moment.locale("zh-cn");
export default {
	name: "client",
	data() {
		return {
			searchForm: {
				parkId: "",
				memberTypeId:"",
				carNo: "",
				entryTime: "",
				outTime: "",
        companyName:"",
				handler:""
			},
			memberTypeList: [],
			handlerList: [],
			parkingList: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			count: {
				parkTimeHour: 0,
				parkTimeMin:0,
				inPark: 0,
				outPark: 0,
				loss: 0,
				lossCoupon: 0,
				lossGoverment: 0,
				lossCooperation: 0,
				lossEmploy: 0,
				incomeTotal:0
			},
			average:{
				parkTimeHour: 0,
				parkTimeMin:0,
				inPark: 0,
				outPark: 0,
				loss: 0,
				incomeTotal:0
			},
			parking: {
				Yunit:'h',
				Xunit:'次',
				xValue: [],//["2020/12/13", "2020/12/14", "2020/12/15", "2020/12/16", "2020/12/17"],
				colors: ["#19D4AE", "#5AB1EF", "#0067A6"],
				listscore: [
					{ "name": "停车时长", "yValue": [] },//[10, 5, 10, 8, 10]
					{ "name": "进场次数", "yValue": [] },//[9, 10, 7, 4, 5]
					{ "name": "出场次数", "yValue": [] }]//[10, 8, 6, 5, 7]
			},
			amount: {
				Yunit:'',
				Xunit:'元',
				xValue: [],//"2020/12/13", "2020/12/14", "2020/12/15", "2020/12/16", "2020/12/17"
				colors: ["#19D4AE","#FA6E86", "#FFB980"],
				listscore: [
					{ "name": "收入金额", "yValue": [] },
					{ "name": "总损失金额", "yValue": [] },
					{ "name": "优惠券损失金额", "yValue": [] },
				/*	{ "name": "政府人员损失金额", "yValue": [] },
					{ "name": "合作伙伴损失金额", "yValue": [] },
					{ "name": "内部人员损失金额", "yValue": [] },*/
				]
			},
			otherLoss: {
				Yunit:'',
				Xunit:'元',
				xValue: [],//"2020/12/13", "2020/12/14", "2020/12/15", "2020/12/16", "2020/12/17"
				colors: ["#197ad4","#dc5bf0", "#19b2d4"],
				listscore: [
					{ "name": "vip客户损失金额", "yValue": [] },
					{ "name": "合作伙伴损失金额", "yValue": [] },
					{ "name": "内部人员损失金额", "yValue": [] },
				]
			},
			dateList:[],
			memberTypeId:0,
			memberInfoList:[],
			total:0
		};
	},
	mounted() {
		this.getMemberInfoTypeList();
		this.getParkingList();
		this.getDurationList();
		this.findHandler();
	},
	methods: {
		getParkingList() {
			let params = {
				page: this.page.current,
				size: 9999
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				this.parkingList = res.data.rows;
			});
		},
		getMemberInfoTypeList() {
			this.$get("/car/memberInfoType/list").then(res => {
				this.memberTypeList = res.data.data;
			});
		},
		calOtherloss(data){
			data.forEach((value) => {
				let map = new Map()
				value.list.forEach(o=>{
					//24小时内不限次数
					if (!map.get(o.carNo)) {
						let diff = moment(o.outTime).diff(o.inTime, 'minutes')
						let sum = 0
						if (o.carType == 'small') {
							if (diff > 30 && diff < 60) {
								sum = sum + 5
							} else if (diff > 240) {
								sum = sum + 20
								//这个车24小时内不限次数，存入map，下次跳过统计
								map.set(o.carNo, 1)
							} else if (diff > 30 && diff < 240) {
								let t = diff - 60
								let num = (t % 30) > 0 ? (Math.trunc(t / 30) * 2) + 7 : Math.trunc(t / 30) * 2 + 5
								sum = sum + num
							}
						}

						if (o.carType == "large") {
							if (diff<=60){
								sum = sum + 10
							} else if (diff >= 1260){
								sum = sum + 30
								map.set(o.carNo,1)
							}else if (diff < 1260){
								let t = diff - 60
								let num = 0
								if (o.parkId == 1 || o.parkId == 2){
									//第一小时起2元/半小时
									num = (t % 30) > 0 ? (Math.trunc(t / 30))*2 + 12 : Math.trunc(t / 30)*2 + 10
								}else {
									num = (t % 60) >0 ? (Math.trunc(t / 60)) + 11 : Math.trunc(t / 60) + 10
								}
								sum = sum + num
							}
						}
						data.get(o.outTimeDay).loss += sum
					}
				})
				map.clear()
			})
			return data
		},
		//查询停车时长和总损失金额
		getDurationList(){
			let diffDay = 5
			if (this.searchForm.entryTime != '' && this.searchForm.outTime != ''){
				this.searchForm.entryTime = moment(this.searchForm.entryTime).format("YYYY-MM-DD")
				this.searchForm.outTime = moment(this.searchForm.outTime).format("YYYY-MM-DD")
				diffDay =  moment(this.searchForm.outTime).diff(this.searchForm.entryTime,'days') + 1
			}else {
				this.searchForm.entryTime = moment().subtract(5,'days').format("YYYY-MM-DD")
				this.searchForm.outTime = moment().format("YYYY-MM-DD")
			}
			let that = this;
			getDurationAndTotalLoss(JSON.stringify(this.searchForm)).then(res=>{

				let list = res.data.data.list
				let entryCountList = res.data.data.entryCount
				let coupLossList = res.data.data.coupLossList

				let govermentlossList = res.data.data.govermentlossList
				//let employLossList = res.data.data.employLossList
				let cooperLossList = res.data.data.cooperLossList
				let map = new Map(),outTimeDayArr=[]
				let goverMap = new Map(),goverArr=[]
				let coopMap = new Map(),coopArr=[]
				this.getDateByDiffTimes();
				list.forEach(o=>{
					map.set(o.outTimeDay,o)
					outTimeDayArr.push(o.outTimeDay)
				})

				entryCountList.forEach(o=>{
					let object = map.get(o.inTimeDay)
					if (object) {
						object.entryCount = o.entryCount
						map.set(o.inTimeDay,object)
					}
				})

				coupLossList.forEach(o=>{
					let object = map.get(o.outTimeDay)
					if (object) {
						object.couponLoass = o.couponLoass
						map.set(o.outTimeDay,object)
					}
				})

				if (this.searchForm.memberTypeId == '' && govermentlossList.length > 0){
					govermentlossList.forEach(o=>{
						if (goverMap.get(o.outTimeDay)) {
							goverMap.get(o.outTimeDay).list.push(o)
						}else {
							goverMap.set(o.outTimeDay,{list:[],loss:0})
							goverMap.get(o.outTimeDay).list.push(o)
						}
					})
					goverMap = this.calOtherloss(goverMap)
				}

				if (this.searchForm.memberTypeId == '' && cooperLossList.length > 0){
					cooperLossList.forEach(o=>{
						if (coopMap.get(o.outTimeDay)) {
							coopMap.get(o.outTimeDay).list.push(o)
						}else {
							coopMap.set(o.outTimeDay,{list:[],loss:0})
							coopMap.get(o.outTimeDay).list.push(o)
						}
					})
					coopMap = this.calOtherloss(coopMap)
				}

				let durationArr=[],outNumArr=[],totalLossArr=[],entryCountArr=[],couponLoassArr=[],incomeTotal=[],employLossArr=[]
				let durationSum=0,outNumSum=0,totalLossSum=0,entryCountSUm=0,couponLoassSum=0,govermentlossSum=0,employLossSum=0,cooperLossSum=0,incomeTotalSum=0
				that.parking.xValue = this.dateList
				that.amount.xValue = this.dateList

				this.otherLoss.xValue = this.dateList
				//计算内部员工损失金额
				this.$get('/car/member-info/memberType',{
					parkId:this.searchForm.parkId,
					memberTypeId:17,
					dataHandler:this.searchForm.handler
				}).then(res=>{
					let dayCount = moment(this.searchForm.entryTime).daysInMonth()
					let count = res.data.data.length
					let emplyAverageLoss = Math.trunc((250*count)/dayCount)
					this.dateList.forEach(o=>{
						if (map.get(o)){
							//停车时长
							if (map.get(o).duration != null && map.get(o).duration != ''){
								durationArr.push((map.get(o).duration)/60/60)
								durationSum = durationSum+map.get(o).duration
							} else {
								durationArr.push(0)
							}
							//出场次数
							if (map.get(o).outNum != null && map.get(o).outNum != ''){
								outNumArr.push(map.get(o).outNum)
								outNumSum = outNumSum+map.get(o).outNum
							} else {
								outNumArr.push(0)
							}
							//进场次数
							if (map.get(o).entryCount != null && map.get(o).entryCount != ''){
								entryCountArr.push(map.get(o).entryCount)
								entryCountSUm = entryCountSUm+map.get(o).entryCount
							} else {
								entryCountArr.push(0)
							}
							//优惠券损失金额
							if (map.get(o).couponLoass != null && map.get(o).couponLoass != ''){
								couponLoassArr.push(map.get(o).couponLoass)
								couponLoassSum = couponLoassSum+map.get(o).couponLoass
							} else {
								couponLoassArr.push(0)
							}
							//收入
							if (map.get(o).incomeTotal != null && map.get(o).incomeTotal != ''){
								incomeTotal.push(map.get(o).incomeTotal)
								incomeTotalSum = incomeTotalSum+map.get(o).incomeTotal
							} else {
								incomeTotal.push(0)
							}
							//总损失
							if (map.get(o).totalLoss != null && map.get(o).totalLoss != ''){
								let totalLoss = map.get(o).totalLoss
								let coopLoss = coopMap.get(o) ? coopMap.get(o).loss : 0
								let goverLoss = goverMap.get(o) ? goverMap.get(o).loss : 0
								totalLossArr.push(totalLoss + coopLoss + goverLoss + emplyAverageLoss)
								totalLossSum = totalLossSum + totalLoss + coopLoss + goverLoss + emplyAverageLoss
							} else {
								totalLossArr.push(0)
							}
						}else{
							durationArr.push(0)
							outNumArr.push(0)
							entryCountArr.push(0)
							couponLoassArr.push(0)
							incomeTotal.push(0)
						}

						if (coopMap.get(o)){
							coopArr.push(coopMap.get(o).loss)
							cooperLossSum = cooperLossSum + coopMap.get(o).loss
						} else {
							coopArr.push(0)
						}

						if (goverMap.get(o)){
							goverArr.push(goverMap.get(o).loss)
							govermentlossSum = govermentlossSum + goverMap.get(o).loss
						} else {
							goverArr.push(0)
						}
						employLossArr.push(emplyAverageLoss)
						employLossSum = employLossSum + emplyAverageLoss

					})

					this.otherLoss.listscore[0].yValue = goverArr
					this.otherLoss.listscore[1].yValue = coopArr
					this.otherLoss.listscore[2].yValue = employLossArr

					//总的数值统计
					this.count.parkTimeHour = Math.floor(durationSum/60/60)
					this.count.parkTimeMin = Math.floor((durationSum/60)%60)
					this.count.outPark = outNumSum
					this.count.loss=totalLossSum
					this.count.inPark=entryCountSUm
					this.count.lossCoupon=couponLoassSum
					this.count.lossGoverment=govermentlossSum
					this.count.lossCooperation=cooperLossSum
					this.count.lossEmploy=employLossSum
					this.count.incomeTotal=incomeTotalSum

					//平均的数值统计
					this.average.parkTimeHour = Math.floor(this.count.parkTimeHour / diffDay)
					this.average.parkTimeMin =  Math.floor(this.count.parkTimeMin / diffDay)
					this.average.outPark =  Math.floor(this.count.outPark / diffDay)
					this.average.inPark =  Math.floor(this.count.inPark / diffDay)
					this.average.loss =  Math.floor(this.count.loss / diffDay)
					this.average.incomeTotal =  Math.floor(this.count.incomeTotal / diffDay)

					that.parking.listscore[0].yValue = durationArr
					that.parking.listscore[1].yValue = entryCountArr
					that.parking.listscore[2].yValue = outNumArr
					that.amount.listscore[0].yValue = incomeTotal
					that.amount.listscore[1].yValue = totalLossArr
					that.amount.listscore[2].yValue = couponLoassArr
				})
			})
		},
		reloadSearch(){
			this.searchForm={
				parkId: "",
				memberTypeId:"",
				carNo: "",
				entryTime: "",
				outTime: ""
			}
			this.getDurationList()
		},
		//查询会员信息的录入人
		findHandler(){
			this.$get('/car/member-info/handler').then(res=>{
				this.handlerList = res.data.data
			})
		},
		getDateByDiffTimes() {
			let begin = moment(this.searchForm.entryTime).format("YYYY-MM-DD");
			let end = moment(this.searchForm.outTime).format("YYYY-MM-DD");
			let diff = moment(end).diff(begin, "days");
			this.dateList = []
			for (let i = 0; i <= diff; i++) {
				this.dateList.push(begin);
				begin = moment(begin).add(1, "days").format("YYYY-MM-DD");
			}
		},
	},
	components: {
		"histogram": () => import("./histogram")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/statistics";
.rightCount{
	width: calc(100% - 73%);
}
</style>
